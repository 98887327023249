import { Link } from "gatsby";
import * as React from "react";
import { Col, Row } from "reactstrap";
import ContentSection from "../../components/contentSection";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const ThankYou: React.FunctionComponent = () => (
    <Layout>
        <SEO title="Thank You" />

        <ContentSection title="Thank You">
            <Row className="justify-content-center">
                <Col lg={6}>
                    <p>
                        Thank you for applying to Tiber Creek. We will review
                        your application and respond as soon as we can.
                    </p>

                    <Link to="/">Return to home page.</Link>
                </Col>
            </Row>
        </ContentSection>
    </Layout>
);

export default ThankYou;
